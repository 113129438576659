













import { BCard } from "bootstrap-vue"
import VueApexCharts from "vue-apexcharts"
import chartOption from "./chartOption"
import useReport, {
  ReportPerDay,
  ReportPerDayResponse,
} from "@/domain/services/useReport"
import { ref } from "vue-demi"

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  setup() {
    const report = useReport("double", "Production")
    console.log("report....")
    const series = ref<ReportPerDayResponse>([
      {
        name: "Green",
        data: report.getEmptyDates(6),
      },
      {
        name: "Loss",
        data: report.getEmptyDates(6),
      },
      {
        name: "Acerto",
        data: report.getEmptyDates(6),
      },
    ])

    report.getReportPerDay(6).then((data) => {
      console.log(data)
      series.value = data
    })

    return {
      chartOption,
      series,
    }
  },
}
