import summaryEntity from "@/domain/entities/summaryEntity"
import SummaryRepository from "@/domain/repositories/SummaryRepository"
import SummaryDataSource from "../api/summaryDataSource"

export default class SummaryRepositoryImpl implements SummaryRepository {
  dataSource: SummaryDataSource
  constructor(dataSource: SummaryDataSource) {
    this.dataSource = dataSource
  }

  getSummary(): Promise<summaryEntity> {
    return this.dataSource.getSummary()
  }
}
