import { $themeColors } from "@themeConfig"
const $trackBgColor = "#EBEBEB"

const chartOption = {
  chartOptions: {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      borderColor: $trackBgColor,
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: -30,
        bottom: -10,
      },
    },
    stroke: {
      width: [3, 3, 0],
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
    },
    fill: {
      opacity: [1, 1, 0.2],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
      },
    },
    colors: [$themeColors.info, $themeColors.danger, $themeColors.warning],
    markers: {
      size: [2, 2, 0],
      colors: $themeColors.info,
      strokeColors: $themeColors.info,
      strokeWidth: 2,
      strokeOpacity: 1,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [
        {
          seriesIndex: 0,
          dataPointIndex: 5,
          fillColor: "#ffffff",
          strokeColor: $themeColors.info,
          size: 5,
        },
      ],
      shape: "circle",
      radius: 2,
      hover: {
        size: 3,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        style: {
          fontSize: "0px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      x: { show: true, format: "dd MMMM yyyy" },
      y: {
        formatter: (y: number, ob: any) => {
          if (ob.seriesIndex == 2) return `${y}%`
          return y
        },
      },
    },
  },
}

export default chartOption
