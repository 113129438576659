import SummaryDataSource from "@/data/api/summaryDataSource"
import SummaryRepositoryImpl from "@/data/repositories/summaryRepositoryImpl"

const SummaryRepository = new SummaryRepositoryImpl(new SummaryDataSource())

export default function useSummary() {
  const getSummary = () => {
    return SummaryRepository.getSummary()
  }

  return {
    getSummary,
  }
}
