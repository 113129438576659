import Summary from "@/domain/entities/summaryEntity"
import summaryEntity from "@/domain/entities/summaryEntity"
import SummaryRepository from "@/domain/repositories/SummaryRepository"
import axios from "@/libs/axios"

export default class SummaryDataSource implements SummaryRepository {
  async getSummary(): Promise<Summary> {
    const response = await axios.get<Summary>("/analytics")

    return response.data
  }
}
