









































import { BCard, BCardText, BRow, BCol } from "bootstrap-vue"
import DoubleStatistic from "./home/DoubleStatistic.vue"
import CrashStatistic from "./home/CrashStatistic.vue"
import WhiteStatistic from "./home/WhiteStatistic.vue"
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue"
import useSummary from "@/domain/services/useSummary"
import Summary from "@/domain/entities/summaryEntity"
import { ref } from "vue-demi"
import { kFormatter } from "@/@core/utils/filter"

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    DoubleStatistic,
    CrashStatistic,
    WhiteStatistic,
    StatisticCardWithAreaChart,
  },
  setup() {
    const { getSummary } = useSummary()
    const summary = ref<Summary | null>(null)
    const toolTipConfig = {
      x: { show: true, format: "MMMM yyyy" },
      y: { formatter: (y: number) => kFormatter(y) },
    }

    const subscribersSeries = ref<
      {
        name: string
        data: number[]
      }[]
    >([])

    getSummary().then((response) => {
      summary.value = response
      console.log(response)
      subscribersSeries.value.push({
        name: "Novos Usuários",
        data: response.user.userCountSeries,
      })
    })

    return {
      subscribersSeries,
      kFormatter,
      summary,
      toolTipConfig,
    }
  },
}
